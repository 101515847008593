import React from 'react';
import './App.less';
import 'antd-mobile/es/global'
import RouteApp from "@/config/router/route-app";

function App() {
    return (
        <React.Suspense>
            <RouteApp/>
        </React.Suspense>
    );
}

export default App;
