export function InitWxShare(title: string, linkUrl: string) {
    // @ts-ignore
    if (window.wx) {
        // @ts-ignore
        window.wx.ready(function () {      //需在用户可能点击分享按钮前就先调用
            // @ts-ignore
            window.wx.onMenuShareAppMessage({
                title: title, // 分享标题
                desc: "合肥天天电子签是国内成熟的电子合同管理平台，您身边的合同管家！", // 分享标题
                link: linkUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: 'https://wx.ttdzq.com/logo192.png', // 分享图标
                success: function () {
                    // 设置成功
                }
            })
            // @ts-ignore
            window.wx.onMenuShareTimeline({
                title: title, // 分享标题
                desc: "合肥天天电子签是国内成熟的电子合同管理平台，您身边的合同管家！", // 分享标题
                link: linkUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: 'https://wx.ttdzq.com/logo192.png', // 分享图标
                success: function () {
                    // 设置成功
                }
            })
            // @ts-ignore
            window.wx.updateAppMessageShareData({
                title: title, // 分享标题
                desc: "合肥天天电子签是国内成熟的电子合同管理平台，您身边的合同管家！", // 分享标题
                link: linkUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: 'https://wx.ttdzq.com/logo192.png', // 分享图标
                success: function () {
                    // 设置成功
                }
            })
            // @ts-ignore
            window.wx.updateTimelineShareData({
                title: title, // 分享标题
                desc: "合肥天天电子签是国内成熟的电子合同管理平台，您身边的合同管家！", // 分享标题
                link: linkUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: 'https://wx.ttdzq.com/logo192.png', // 分享图标
                success: function () {
                    // 设置成功
                }
            })

        });
    }
}